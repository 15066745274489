



















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import {Action} from 'vuex-class';
import VcSwitch from '@/components/VCSwitch.vue';
import {timeToFrom} from '@/utils/helpers';
import {profileStore} from '@/store/modules/profile';
import DialogMuteTimeSettings from '@/components/profile/DialogMuteTimeSettings.vue';

@Component({
  name: 'profile-info-working-status',
  components: {DialogMuteTimeSettings, VcSwitch, ContactInfoCard}
})
export default class ProfileInfoWorkingStatus extends Vue {

  showChangeWorkingStatusDialog: boolean = false
  saving: boolean = false
  messageLimit: number = 150;

  @Action changeAdminRole;

  get associate() {
    return profileStore.t2bUser;
  }

  get useMessage() {
    return profileStore.useAwayMessage
  }

  set useMessage(value: boolean) {
    profileStore.setUseAwayMessage(value)
  }

  get message() {
    return profileStore.awayMessage
  }

  set message(value: string) {
    const limitMessage: string = value.substring(0, this.messageLimit);
    profileStore.setAwayMessage(limitMessage);
  }

  get workingStatus() {
    return this.associate?.workingStatus?.name;
  }

  get statusTimeMessage() {
    const targetDate = this.associate?.workingStatus?.time?.toDate()?.getTime() || 0;
    if (this.active) {
      const diff = timeToFrom(targetDate, null, false)
      return diff ? `More than ${diff}` : 'Just now'
    }
    const duration = this.associate?.workingStatus?.duration || 0;
    const diff = timeToFrom(targetDate + duration, null)
    return diff ? `Absent for ${diff}` : 'Until I turn it back on'
  }

  get active() {
    return this.associate?.workingStatus?.type === 1;
  }

  get admin() {
    return this.associate?.roles?.admin || this.associate?.admin;
  }

  set admin(value) {
    try {
      this.changeAdminRole({id: this.associate!.id, admin: value});
      console.log(`set admin. value: ${value}`);
    } catch (err) {
      console.log(`set admin. error: ${err}`);
    }
  }

  get email() {
    return this.associate!.email;
  }

  set email(email) {
    this.associate!.email = email;
  }

  get position() {
    return this.associate!.position;
  }

  set position(position) {
    this.associate!.position = position;
  }

  get phone() {
    return this.associate!.phoneNumber;
  }

  set phone(phone) {
    this.associate!.phoneNumber = phone;
  }

  @Watch('useMessage')
  onUseMessageChanged(value?: boolean, oldValue?: boolean) {
    if (oldValue === undefined || value === oldValue) {
      return
    }
    profileStore.saveUseMessage(value)
  }

  onDiscard() {
    if (this.message.length > 0) {
      this.message = '';
      this.onSaveMessage();
    }
  }

  async onSaveMessage() {
    this.saving = true
    await profileStore.saveAwayMessage(this.message)
    this.saving = false
  }

  async onSaveWorkingStatusSettings(period: number = 0) {
    await profileStore.changeWorkingStatus(period)
  }

  onToggle() {
    if (!this.active) {
      this.onSaveWorkingStatusSettings()
      return
    }
    this.showChangeWorkingStatusDialog = true
  }
}
