import store from '@/store';
import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {
  AlertSetting,
  AppointsSetting,
  AwaySetting,
  BackupSetting,
  BusinessPageAccessSetting,
  ContactsSetting,
  Settings,
  SettingsState,
  WorkingDay
} from '@/domain/model/types';
import {getDoc, serverTimestamp, setDoc, Timestamp, updateDoc} from 'firebase/firestore';
import rfdc from 'rfdc';
import {businessDirectoryContact, businessSettings} from '@/data/firebase';

const clone = rfdc({proto: true})

const openTime: string = '8:00 AM'
const closeTime: string = '9:00 PM'
const from12AM: string = '12:00 AM'
const to11PM: string = '11:59 PM'

const defaultSettings: Settings = {
  alwaysOpen: false,
  timeZone: '',
  timeZoneIndex: -1,
  workingDays: [
    {
      name: 'Sunday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Monday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Tuesday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Wednesday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Thursday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Friday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    },
    {
      name: 'Saturday',
      from: openTime,
      to: closeTime,
      start: openTime,
      end: closeTime,
      active: false
    }
  ],
  autoAlert: {
    active: false,
    contacts: [],
    emails: [],
    // new fields
    requestNew: false,
    requestIdle: false,
    requestIdleTime: 5 * 60 * 1000,
    requestIdleRepeat: 1,
    chatExternalUnread: false,
    chatExternalUnreadTime: 5 * 60 * 1000,
    chatExternalUnreadRepeat: 1,
    chatExternalIdle: false,
    chatExternalIdleTime: 5 * 60 * 1000,
    chatExternalIdleRepeat: 1,
    chatInternalUnread: false,
    chatInternalUnreadTime: 5 * 60 * 1000,
    chatInternalUnreadRepeat: 1,
    appointmentNew: false,
    appointmentReminder: false,
    appointmentReminderTime: 5 * 60 * 1000,
    appointmentReminderRepeat: 1,
    summaryDailyActivity: false,
    summaryDailyBusiness: false
  },
  away: {
    active: false,
    closedMessage: null,
    awayMessage: null
  },
  backup: {
    active: false,
    contact: null,
    autoForward: false,
    autoGroup: false
  },
  appoints: {
    active: false,
    contact: null,
    appointDays: [
      {
        name: 'Sunday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Monday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Tuesday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Wednesday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Thursday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Friday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      },
      {
        name: 'Saturday',
        from: openTime,
        to: closeTime,
        start: openTime,
        end: closeTime,
        active: false
      }
    ],
    period: 30,
    remind: null,
    syncGoogle: false
  },
  contacts: {autoSharing: false},
  accessProtection: {
    active: false,
    code: ''
  },
};

const timeZones = [
  {
    value: 'UTC-11:00',
    name: 'Niue Time',
    numvalue: -11,
    index: 0
  },
  {
    value: 'UTC-11:00',
    name: 'Samoa Standard Time',
    numvalue: -11,
    index: 1
  },
  {
    value: 'UTC-10:00',
    name: 'Cook Islands Standard Time',
    numvalue: -10,
    index: 2
  },
  {
    value: 'UTC-10:00',
    name: 'Hawaii-Aleutian Standard Time',
    numvalue: -10,
    index: 3
  },
  {
    value: 'UTC-10:00',
    name: 'Tahiti Time',
    numvalue: -10,
    index: 4
  },
  {
    value: 'UTC-09:30',
    name: 'Marquesas Time',
    numvalue: -8.5,
    index: 5
  },
  {
    value: 'UTC-09:00',
    name: 'Gambier Time',
    numvalue: -9,
    index: 6
  },
  {
    value: 'UTC-09:00',
    name: 'Hawaii-Aleutian Time (Adak)',
    numvalue: -9,
    index: 7
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Anchorage',
    numvalue: -8,
    index: 8
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Juneau',
    numvalue: -8,
    index: 9
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Metlakatla',
    numvalue: -8,
    index: 10
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Nome',
    numvalue: -8,
    index: 11
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Sitka',
    numvalue: -8,
    index: 12
  },
  {
    value: 'UTC-08:00',
    name: 'Alaska Time - Yakutat',
    numvalue: -8,
    index: 13
  },
  {
    value: 'UTC-08:00',
    name: 'Pitcairn Time',
    numvalue: -8,
    index: 14
  },
  {
    value: 'UTC-07:00',
    name: 'Mexican Pacific Standard Time',
    numvalue: -7,
    index: 15
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Creston',
    numvalue: -7,
    index: 16
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Dawson',
    numvalue: -7,
    index: 17
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Dawson Creek',
    numvalue: -7,
    index: 18
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Fort Nelson',
    numvalue: -7,
    index: 19
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Phoenix',
    numvalue: -7,
    index: 20
  },
  {
    value: 'UTC-07:00',
    name: 'Mountain Standard Time - Whitehorse',
    numvalue: -7,
    index: 21
  },
  {
    value: 'UTC-07:00',
    name: 'Pacific Time - Los Angeles',
    numvalue: -7,
    index: 22
  },
  {
    value: 'UTC-07:00',
    name: 'Pacific Time - Tijuana',
    numvalue: -7,
    index: 23
  },
  {
    value: 'UTC-07:00',
    name: 'Pacific Time - Vancouver',
    numvalue: -7,
    index: 24
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Belize',
    numvalue: -6,
    index: 25
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Costa Rica',
    numvalue: -6,
    index: 26
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - El Salvador',
    numvalue: -6,
    index: 27
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Guatemala',
    numvalue: -6,
    index: 28
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Managua',
    numvalue: -6,
    index: 29
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Regina',
    numvalue: -6,
    index: 30
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Swift Current',
    numvalue: -6,
    index: 31
  },
  {
    value: 'UTC-06:00',
    name: 'Central Standard Time - Tegucigalpa',
    numvalue: -6,
    index: 32
  },
  {
    value: 'UTC-06:00',
    name: 'Easter Island Time',
    numvalue: -6,
    index: 33
  },
  {
    value: 'UTC-06:00',
    name: 'Galapagos Time',
    numvalue: -6,
    index: 34
  },
  {
    value: 'UTC-06:00',
    name: 'Mexican Pacific Time - Chihuahua',
    numvalue: -6,
    index: 35
  },
  {
    value: 'UTC-06:00',
    name: 'Mexican Pacific Time - Mazatlan',
    numvalue: -6,
    index: 36
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Boise',
    numvalue: -6,
    index: 37
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Cambridge Bay',
    numvalue: -6,
    index: 38
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Denver',
    numvalue: -6,
    index: 39
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Edmonton',
    numvalue: -6,
    index: 40
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Inuvik',
    numvalue: -6,
    index: 41
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Ojinaga',
    numvalue: -6,
    index: 42
  },
  {
    value: 'UTC-06:00',
    name: 'Mountain Time - Yellowknife',
    numvalue: -6,
    index: 43
  },
  {
    value: 'UTC-05:00',
    name: 'Acre Standard Time - Eirunepe',
    numvalue: -5,
    index: 44
  },
  {
    value: 'UTC-05:00',
    name: 'Acre Standard Time - Rio Branco',
    numvalue: -5,
    index: 45
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Bahia Banderas',
    numvalue: -5,
    index: 46
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Beulah, North Dakota',
    numvalue: -5,
    index: 47
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Center, North Dakota',
    numvalue: -5,
    index: 48
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Chicago',
    numvalue: -5,
    index: 49
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Knox, Indiana',
    numvalue: -5,
    index: 50
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Matamoros',
    numvalue: -5,
    index: 51
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Menominee',
    numvalue: -5,
    index: 52
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Merida',
    numvalue: -5,
    index: 53
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Mexico City',
    numvalue: -5,
    index: 54
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Monterrey',
    numvalue: -5,
    index: 55
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - New Salem, North Dakota',
    numvalue: -5,
    index: 56
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Rainy River',
    numvalue: -5,
    index: 57
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Rankin Inlet',
    numvalue: -5,
    index: 58
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Resolute',
    numvalue: -5,
    index: 59
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Tell City, Indiana',
    numvalue: -5,
    index: 60
  },
  {
    value: 'UTC-05:00',
    name: 'Central Time - Winnipeg',
    numvalue: -5,
    index: 61
  },
  {
    value: 'UTC-05:00',
    name: 'Colombia Standard Time',
    numvalue: -5,
    index: 62
  },
  {
    value: 'UTC-05:00',
    name: 'Eastern Standard Time - Atikokan',
    numvalue: -5,
    index: 63
  },
  {
    value: 'UTC-05:00',
    name: 'Eastern Standard Time - Cancun',
    numvalue: -5,
    index: 64
  },
  {
    value: 'UTC-05:00',
    name: 'Eastern Standard Time - Jamaica',
    numvalue: -5,
    index: 65
  },
  {
    value: 'UTC-05:00',
    name: 'Eastern Standard Time - Panama',
    numvalue: -5,
    index: 66
  },
  {
    value: 'UTC-05:00',
    name: 'Ecuador Time',
    numvalue: -5,
    index: 67
  },
  {
    value: 'UTC-05:00',
    name: 'Peru Standard Time',
    numvalue: -5,
    index: 68
  },
  {
    value: 'UTC-04:00',
    name: 'Amazon Standard Time - Boa Vista',
    numvalue: -4,
    index: 69
  },
  {
    value: 'UTC-04:00',
    name: 'Amazon Standard Time - Campo Grande',
    numvalue: -4,
    index: 70
  },
  {
    value: 'UTC-04:00',
    name: 'Amazon Standard Time - Cuiaba',
    numvalue: -4,
    index: 71
  },
  {
    value: 'UTC-04:00',
    name: 'Amazon Standard Time - Manaus',
    numvalue: -4,
    index: 72
  },
  {
    value: 'UTC-04:00',
    name: 'Amazon Standard Time - Porto Velho',
    numvalue: -4,
    index: 73
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Barbados',
    numvalue: -4,
    index: 74
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Blanc-Sablon',
    numvalue: -4,
    index: 75
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Curaçao',
    numvalue: -4,
    index: 76
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Martinique',
    numvalue: -4,
    index: 77
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Port of Spain',
    numvalue: -4,
    index: 78
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Puerto Rico',
    numvalue: -4,
    index: 79
  },
  {
    value: 'UTC-04:00',
    name: 'Atlantic Standard Time - Santo Domingo',
    numvalue: -4,
    index: 80
  },
  {
    value: 'UTC-04:00',
    name: 'Bolivia Time',
    numvalue: -4,
    index: 81
  },
  {
    value: 'UTC-04:00',
    name: 'Chile Time',
    numvalue: -4,
    index: 82
  },
  {
    value: 'UTC-04:00',
    name: 'Cuba Time',
    numvalue: -4,
    index: 83
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Detroit',
    numvalue: -4,
    index: 84
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Grand Turk',
    numvalue: -4,
    index: 85
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Indianapolis',
    numvalue: -4,
    index: 86
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Iqaluit',
    numvalue: -4,
    index: 87
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Louisville',
    numvalue: -4,
    index: 88
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Marengo, Indiana',
    numvalue: -4,
    index: 89
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Monticello, Kentucky',
    numvalue: -4,
    index: 90
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Nassau',
    numvalue: -4,
    index: 91
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - New York',
    numvalue: -4,
    index: 92
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Nipigon',
    numvalue: -4,
    index: 93
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Pangnirtung',
    numvalue: -4,
    index: 94
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Petersburg, Indiana',
    numvalue: -4,
    index: 95
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Port-au-Prince',
    numvalue: -4,
    index: 96
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Thunder Bay',
    numvalue: -4,
    index: 97
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Toronto',
    numvalue: -4,
    index: 98
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Vevay, Indiana',
    numvalue: -4,
    index: 99
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Vincennes, Indiana',
    numvalue: -4,
    index: 100
  },
  {
    value: 'UTC-04:00',
    name: 'Eastern Time - Winamac, Indiana',
    numvalue: -4,
    index: 101
  },
  {
    value: 'UTC-04:00',
    name: 'Guyana Time',
    numvalue: -4,
    index: 102
  },
  {
    value: 'UTC-04:00',
    name: 'Paraguay Time',
    numvalue: -4,
    index: 103
  },
  {
    value: 'UTC-04:00',
    name: 'Venezuela Time',
    numvalue: -4,
    index: 104
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Buenos Aires',
    numvalue: -3,
    index: 105
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Catamarca',
    numvalue: -3,
    index: 106
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Cordoba',
    numvalue: -3,
    index: 107
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Jujuy',
    numvalue: -3,
    index: 108
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - La Rioja',
    numvalue: -3,
    index: 109
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Mendoza',
    numvalue: -3,
    index: 110
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Rio Gallegos',
    numvalue: -3,
    index: 111
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Salta',
    numvalue: -3,
    index: 112
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - San Juan',
    numvalue: -3,
    index: 113
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - San Luis',
    numvalue: -3,
    index: 114
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Tucuman',
    numvalue: -3,
    index: 115
  },
  {
    value: 'UTC-03:00',
    name: 'Argentina Standard Time - Ushuaia',
    numvalue: -3,
    index: 116
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Bermuda',
    numvalue: -3,
    index: 117
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Glace Bay',
    numvalue: -3,
    index: 118
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Goose Bay',
    numvalue: -3,
    index: 119
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Halifax',
    numvalue: -3,
    index: 120
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Moncton',
    numvalue: -3,
    index: 121
  },
  {
    value: 'UTC-03:00',
    name: 'Atlantic Time - Thule',
    numvalue: -3,
    index: 122
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Araguaina',
    numvalue: -3,
    index: 123
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Bahia',
    numvalue: -3,
    index: 124
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Belem',
    numvalue: -3,
    index: 125
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Fortaleza',
    numvalue: -3,
    index: 126
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Maceio',
    numvalue: -3,
    index: 127
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Recife',
    numvalue: -3,
    index: 128
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Santarem',
    numvalue: -3,
    index: 129
  },
  {
    value: 'UTC-03:00',
    name: 'Brasilia Standard Time - Sao Paulo',
    numvalue: -3,
    index: 130
  },
  {
    value: 'UTC-03:00',
    name: 'Falkland Islands Standard Time',
    numvalue: -3,
    index: 131
  },
  {
    value: 'UTC-03:00',
    name: 'French Guiana Time',
    numvalue: -3,
    index: 132
  },
  {
    value: 'UTC-03:00',
    name: 'Palmer Time',
    numvalue: -3,
    index: 133
  },
  {
    value: 'UTC-03:00',
    name: 'Punta Arenas Time',
    numvalue: -3,
    index: 134
  },
  {
    value: 'UTC-03:00',
    name: 'Rothera Time',
    numvalue: -3,
    index: 135
  },
  {
    value: 'UTC-03:00',
    name: 'Suriname Time',
    numvalue: -3,
    index: 136
  },
  {
    value: 'UTC-03:00',
    name: 'Uruguay Standard Time',
    numvalue: -3,
    index: 137
  },
  {
    value: 'UTC-02:30',
    name: 'Newfoundland Time',
    numvalue: -1.5,
    index: 138
  },
  {
    value: 'UTC-02:00',
    name: 'Fernando de Noronha Standard Time',
    numvalue: -2,
    index: 139
  },
  {
    value: 'UTC-02:00',
    name: 'South Georgia Time',
    numvalue: -2,
    index: 140
  },
  {
    value: 'UTC-02:00',
    name: 'St. Pierre \u0026 Miquelon Time',
    numvalue: -2,
    index: 141
  },
  {
    value: 'UTC-02:00',
    name: 'West Greenland Time',
    numvalue: -2,
    index: 142
  },
  {
    value: 'UTC-01:00',
    name: 'Cape Verde Standard Time',
    numvalue: -1,
    index: 143
  },
  {
    value: 'UTC+00:00',
    name: 'Azores Time',
    numvalue: 0,
    index: 144
  },
  {
    value: 'UTC+00:00',
    name: 'Coordinated Universal Time',
    numvalue: 0,
    index: 145
  },
  {
    value: 'UTC+00:00',
    name: 'East Greenland Time',
    numvalue: 0,
    index: 146
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time',
    numvalue: 0,
    index: 147
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Abidjan',
    numvalue: 0,
    index: 148
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Accra',
    numvalue: 0,
    index: 149
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Bissau',
    numvalue: 0,
    index: 150
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Danmarkshavn',
    numvalue: 0,
    index: 151
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Monrovia',
    numvalue: 0,
    index: 152
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - Reykjavik',
    numvalue: 0,
    index: 153
  },
  {
    value: 'UTC+00:00',
    name: 'Greenwich Mean Time - São Tomé',
    numvalue: 0,
    index: 154
  },
  {
    value: 'UTC+01:00',
    name: 'Central European Standard Time - Algiers',
    numvalue: 1,
    index: 155
  },
  {
    value: 'UTC+01:00',
    name: 'Central European Standard Time - Tunis',
    numvalue: 1,
    index: 156
  },
  {
    value: 'UTC+01:00',
    name: 'Ireland Time',
    numvalue: 1,
    index: 157
  },
  {
    value: 'UTC+01:00',
    name: 'Morocco Time',
    numvalue: 1,
    index: 158
  },
  {
    value: 'UTC+01:00',
    name: 'United Kingdom Time',
    numvalue: 1,
    index: 159
  },
  {
    value: 'UTC+01:00',
    name: 'West Africa Standard Time - Lagos',
    numvalue: 1,
    index: 160
  },
  {
    value: 'UTC+01:00',
    name: 'West Africa Standard Time - Ndjamena',
    numvalue: 1,
    index: 161
  },
  {
    value: 'UTC+01:00',
    name: 'Western European Time - Canary',
    numvalue: 1,
    index: 162
  },
  {
    value: 'UTC+01:00',
    name: 'Western European Time - Faroe',
    numvalue: 1,
    index: 163
  },
  {
    value: 'UTC+01:00',
    name: 'Western European Time - Lisbon',
    numvalue: 1,
    index: 164
  },
  {
    value: 'UTC+01:00',
    name: 'Western European Time - Madeira',
    numvalue: 1,
    index: 165
  },
  {
    value: 'UTC+01:00',
    name: 'Western Sahara Time',
    numvalue: 1,
    index: 166
  },
  {
    value: 'UTC+02:00',
    name: 'Central Africa Time - Juba',
    numvalue: 2,
    index: 167
  },
  {
    value: 'UTC+02:00',
    name: 'Central Africa Time - Khartoum',
    numvalue: 2,
    index: 168
  },
  {
    value: 'UTC+02:00',
    name: 'Central Africa Time - Maputo',
    numvalue: 2,
    index: 169
  },
  {
    value: 'UTC+02:00',
    name: 'Central Africa Time - Windhoek',
    numvalue: 2,
    index: 170
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Amsterdam',
    numvalue: 2,
    index: 171
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Andorra',
    numvalue: 2,
    index: 172
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Belgrade',
    numvalue: 2,
    index: 173
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Berlin',
    numvalue: 2,
    index: 174
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Brussels',
    numvalue: 2,
    index: 175
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Budapest',
    numvalue: 2,
    index: 176
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Ceuta',
    numvalue: 2,
    index: 177
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Copenhagen',
    numvalue: 2,
    index: 178
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Gibraltar',
    numvalue: 2,
    index: 179
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Luxembourg',
    numvalue: 2,
    index: 180
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Madrid',
    numvalue: 2,
    index: 181
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Malta',
    numvalue: 2,
    index: 182
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Monaco',
    numvalue: 2,
    index: 183
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Oslo',
    numvalue: 2,
    index: 184
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Paris',
    numvalue: 2,
    index: 185
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Prague',
    numvalue: 2,
    index: 186
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Rome',
    numvalue: 2,
    index: 187
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Stockholm',
    numvalue: 2,
    index: 188
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Tirane',
    numvalue: 2,
    index: 189
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Vienna',
    numvalue: 2,
    index: 190
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Warsaw',
    numvalue: 2,
    index: 191
  },
  {
    value: 'UTC+02:00',
    name: 'Central European Time - Zurich',
    numvalue: 2,
    index: 192
  },
  {
    value: 'UTC+02:00',
    name: 'Eastern European Standard Time - Cairo',
    numvalue: 2,
    index: 193
  },
  {
    value: 'UTC+02:00',
    name: 'Eastern European Standard Time - Kaliningrad',
    numvalue: 2,
    index: 194
  },
  {
    value: 'UTC+02:00',
    name: 'Eastern European Standard Time - Tripoli',
    numvalue: 2,
    index: 195
  },
  {
    value: 'UTC+02:00',
    name: 'South Africa Standard Time',
    numvalue: 2,
    index: 196
  },
  {
    value: 'UTC+02:00',
    name: 'Troll Time',
    numvalue: 2,
    index: 197
  },
  {
    value: 'UTC+03:00',
    name: 'Arabian Standard Time - Baghdad',
    numvalue: 3,
    index: 198
  },
  {
    value: 'UTC+03:00',
    name: 'Arabian Standard Time - Qatar',
    numvalue: 3,
    index: 199
  },
  {
    value: 'UTC+03:00',
    name: 'Arabian Standard Time - Riyadh',
    numvalue: 3,
    index: 200
  },
  {
    value: 'UTC+03:00',
    name: 'East Africa Time',
    numvalue: 3,
    index: 201
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Amman',
    numvalue: 3,
    index: 202
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Athens',
    numvalue: 3,
    index: 203
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Beirut',
    numvalue: 3,
    index: 204
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Bucharest',
    numvalue: 3,
    index: 205
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Chisinau',
    numvalue: 3,
    index: 206
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Damascus',
    numvalue: 3,
    index: 207
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Gaza',
    numvalue: 3,
    index: 208
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Hebron',
    numvalue: 3,
    index: 209
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Helsinki',
    numvalue: 3,
    index: 210
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Kiev',
    numvalue: 3,
    index: 211
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Nicosia',
    numvalue: 3,
    index: 212
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Riga',
    numvalue: 3,
    index: 213
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Sofia',
    numvalue: 3,
    index: 214
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Tallinn',
    numvalue: 3,
    index: 215
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Uzhhorod',
    numvalue: 3,
    index: 216
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Vilnius',
    numvalue: 3,
    index: 217
  },
  {
    value: 'UTC+03:00',
    name: 'Eastern European Time - Zaporozhye',
    numvalue: 3,
    index: 218
  },
  {
    value: 'UTC+03:00',
    name: 'Famagusta Time',
    numvalue: 3,
    index: 219
  },
  {
    value: 'UTC+03:00',
    name: 'Israel Time',
    numvalue: 3,
    index: 220
  },
  {
    value: 'UTC+03:00',
    name: 'Kirov Time',
    numvalue: 3,
    index: 221
  },
  {
    value: 'UTC+03:00',
    name: 'Moscow Standard Time - Minsk',
    numvalue: 3,
    index: 222
  },
  {
    value: 'UTC+03:00',
    name: 'Moscow Standard Time - Moscow',
    numvalue: 3,
    index: 223
  },
  {
    value: 'UTC+03:00',
    name: 'Moscow Standard Time - Simferopol',
    numvalue: 3,
    index: 224
  },
  {
    value: 'UTC+03:00',
    name: 'Syowa Time',
    numvalue: 3,
    index: 225
  },
  {
    value: 'UTC+03:00',
    name: 'Turkey Time',
    numvalue: 3,
    index: 226
  },
  {
    value: 'UTC+03:00',
    name: 'Volgograd Standard Time',
    numvalue: 3,
    index: 227
  },
  {
    value: 'UTC+04:00',
    name: 'Armenia Standard Time',
    numvalue: 4,
    index: 228
  },
  {
    value: 'UTC+04:00',
    name: 'Astrakhan Time',
    numvalue: 4,
    index: 229
  },
  {
    value: 'UTC+04:00',
    name: 'Azerbaijan Standard Time',
    numvalue: 4,
    index: 230
  },
  {
    value: 'UTC+04:00',
    name: 'Georgia Standard Time',
    numvalue: 4,
    index: 231
  },
  {
    value: 'UTC+04:00',
    name: 'Gulf Standard Time',
    numvalue: 4,
    index: 232
  },
  {
    value: 'UTC+04:00',
    name: 'Mauritius Standard Time',
    numvalue: 4,
    index: 233
  },
  {
    value: 'UTC+04:00',
    name: 'Réunion Time',
    numvalue: 4,
    index: 234
  },
  {
    value: 'UTC+04:00',
    name: 'Samara Standard Time',
    numvalue: 4,
    index: 235
  },
  {
    value: 'UTC+04:00',
    name: 'Saratov Time',
    numvalue: 4,
    index: 236
  },
  {
    value: 'UTC+04:00',
    name: 'Seychelles Time',
    numvalue: 4,
    index: 237
  },
  {
    value: 'UTC+04:00',
    name: 'Ulyanovsk Time',
    numvalue: 4,
    index: 238
  },
  {
    value: 'UTC+04:30',
    name: 'Afghanistan Time',
    numvalue: 4.5,
    index: 239
  },
  {
    value: 'UTC+04:30',
    name: 'Iran Time',
    numvalue: 4.5,
    index: 240
  },
  {
    value: 'UTC+05:00',
    name: 'French Southern \u0026 Antarctic Time',
    numvalue: 5,
    index: 241
  },
  {
    value: 'UTC+05:00',
    name: 'Maldives Time',
    numvalue: 5,
    index: 242
  },
  {
    value: 'UTC+05:00',
    name: 'Mawson Time',
    numvalue: 5,
    index: 243
  },
  {
    value: 'UTC+05:00',
    name: 'Pakistan Standard Time',
    numvalue: 5,
    index: 244
  },
  {
    value: 'UTC+05:00',
    name: 'Tajikistan Time',
    numvalue: 5,
    index: 245
  },
  {
    value: 'UTC+05:00',
    name: 'Turkmenistan Standard Time',
    numvalue: 5,
    index: 246
  },
  {
    value: 'UTC+05:00',
    name: 'Uzbekistan Standard Time - Samarkand',
    numvalue: 5,
    index: 247
  },
  {
    value: 'UTC+05:00',
    name: 'Uzbekistan Standard Time - Tashkent',
    numvalue: 5,
    index: 248
  },
  {
    value: 'UTC+05:00',
    name: 'West Kazakhstan Time - Aqtau',
    numvalue: 5,
    index: 249
  },
  {
    value: 'UTC+05:00',
    name: 'West Kazakhstan Time - Aqtobe',
    numvalue: 5,
    index: 250
  },
  {
    value: 'UTC+05:00',
    name: 'West Kazakhstan Time - Atyrau',
    numvalue: 5,
    index: 251
  },
  {
    value: 'UTC+05:00',
    name: 'West Kazakhstan Time - Oral',
    numvalue: 5,
    index: 252
  },
  {
    value: 'UTC+05:00',
    name: 'West Kazakhstan Time - Qyzylorda',
    numvalue: 5,
    index: 253
  },
  {
    value: 'UTC+05:00',
    name: 'Yekaterinburg Standard Time',
    numvalue: 5,
    index: 254
  },
  {
    value: 'UTC+05:30',
    name: 'India Standard Time - Colombo',
    numvalue: 5.5,
    index: 255
  },
  {
    value: 'UTC+05:30',
    name: 'India Standard Time - Kolkata',
    numvalue: 5.5,
    index: 256
  },
  {
    value: 'UTC+05:45',
    name: 'Nepal Time',
    numvalue: 5.75,
    index: 257
  },
  {
    value: 'UTC+06:00',
    name: 'Bangladesh Standard Time',
    numvalue: 6,
    index: 258
  },
  {
    value: 'UTC+06:00',
    name: 'Bhutan Time',
    numvalue: 6,
    index: 259
  },
  {
    value: 'UTC+06:00',
    name: 'East Kazakhstan Time - Almaty',
    numvalue: 6,
    index: 260
  },
  {
    value: 'UTC+06:00',
    name: 'East Kazakhstan Time - Kostanay',
    numvalue: 6,
    index: 261
  },
  {
    value: 'UTC+06:00',
    name: 'Indian Ocean Time',
    numvalue: 6,
    index: 262
  },
  {
    value: 'UTC+06:00',
    name: 'Kyrgyzstan Time',
    numvalue: 6,
    index: 263
  },
  {
    value: 'UTC+06:00',
    name: 'Omsk Standard Time',
    numvalue: 6,
    index: 264
  },
  {
    value: 'UTC+06:00',
    name: 'Urumqi Time',
    numvalue: 6,
    index: 265
  },
  {
    value: 'UTC+06:00',
    name: 'Vostok Time',
    numvalue: 6,
    index: 266
  },
  {
    value: 'UTC+06:30',
    name: 'Cocos Islands Time',
    numvalue: 6.5,
    index: 267
  },
  {
    value: 'UTC+06:30',
    name: 'Myanmar Time',
    numvalue: 6.5,
    index: 268
  },
  {
    value: 'UTC+07:00',
    name: 'Barnaul Time',
    numvalue: 7,
    index: 269
  },
  {
    value: 'UTC+07:00',
    name: 'Christmas Island Time',
    numvalue: 7,
    index: 270
  },
  {
    value: 'UTC+07:00',
    name: 'Davis Time',
    numvalue: 7,
    index: 271
  },
  {
    value: 'UTC+07:00',
    name: 'Hovd Standard Time',
    numvalue: 7,
    index: 272
  },
  {
    value: 'UTC+07:00',
    name: 'Indochina Time - Bangkok',
    numvalue: 7,
    index: 273
  },
  {
    value: 'UTC+07:00',
    name: 'Indochina Time - Ho Chi Minh City',
    numvalue: 7,
    index: 274
  },
  {
    value: 'UTC+07:00',
    name: 'Krasnoyarsk Standard Time - Krasnoyarsk',
    numvalue: 7,
    index: 275
  },
  {
    value: 'UTC+07:00',
    name: 'Krasnoyarsk Standard Time - Novokuznetsk',
    numvalue: 7,
    index: 276
  },
  {
    value: 'UTC+07:00',
    name: 'Novosibirsk Standard Time',
    numvalue: 7,
    index: 277
  },
  {
    value: 'UTC+07:00',
    name: 'Tomsk Time',
    numvalue: 7,
    index: 278
  },
  {
    value: 'UTC+07:00',
    name: 'Western Indonesia Time - Jakarta',
    numvalue: 7,
    index: 279
  },
  {
    value: 'UTC+07:00',
    name: 'Western Indonesia Time - Pontianak',
    numvalue: 7,
    index: 280
  },
  {
    value: 'UTC+08:00',
    name: 'Australian Western Standard Time',
    numvalue: 8,
    index: 281
  },
  {
    value: 'UTC+08:00',
    name: 'Brunei Darussalam Time',
    numvalue: 8,
    index: 282
  },
  {
    value: 'UTC+08:00',
    name: 'Central Indonesia Time',
    numvalue: 8,
    index: 283
  },
  {
    value: 'UTC+08:00',
    name: 'China Standard Time - Macao',
    numvalue: 8,
    index: 284
  },
  {
    value: 'UTC+08:00',
    name: 'China Standard Time - Shanghai',
    numvalue: 8,
    index: 285
  },
  {
    value: 'UTC+08:00',
    name: 'Hong Kong Standard Time',
    numvalue: 8,
    index: 286
  },
  {
    value: 'UTC+08:00',
    name: 'Irkutsk Standard Time',
    numvalue: 8,
    index: 287
  },
  {
    value: 'UTC+08:00',
    name: 'Malaysia Time - Kuala Lumpur',
    numvalue: 8,
    index: 288
  },
  {
    value: 'UTC+08:00',
    name: 'Malaysia Time - Kuching',
    numvalue: 8,
    index: 289
  },
  {
    value: 'UTC+08:00',
    name: 'Philippine Standard Time',
    numvalue: 8,
    index: 290
  },
  {
    value: 'UTC+08:00',
    name: 'Singapore Standard Time',
    numvalue: 8,
    index: 291
  },
  {
    value: 'UTC+08:00',
    name: 'Taipei Standard Time',
    numvalue: 8,
    index: 292
  },
  {
    value: 'UTC+08:00',
    name: 'Ulaanbaatar Standard Time - Choibalsan',
    numvalue: 8,
    index: 293
  },
  {
    value: 'UTC+08:00',
    name: 'Ulaanbaatar Standard Time - Ulaanbaatar',
    numvalue: 8,
    index: 294
  },
  {
    value: 'UTC+08:45',
    name: 'Australian Central Western Standard Time',
    numvalue: 8.75,
    index: 295
  },
  {
    value: 'UTC+09:00',
    name: 'East Timor Time',
    numvalue: 9,
    index: 296
  },
  {
    value: 'UTC+09:00',
    name: 'Eastern Indonesia Time',
    numvalue: 9,
    index: 297
  },
  {
    value: 'UTC+09:00',
    name: 'Japan Standard Time',
    numvalue: 9,
    index: 298
  },
  {
    value: 'UTC+09:00',
    name: 'Korean Standard Time - Pyongyang',
    numvalue: 9,
    index: 299
  },
  {
    value: 'UTC+09:00',
    name: 'Korean Standard Time - Seoul',
    numvalue: 9,
    index: 300
  },
  {
    value: 'UTC+09:00',
    name: 'Palau Time',
    numvalue: 9,
    index: 301
  },
  {
    value: 'UTC+09:00',
    name: 'Yakutsk Standard Time - Chita',
    numvalue: 9,
    index: 302
  },
  {
    value: 'UTC+09:00',
    name: 'Yakutsk Standard Time - Khandyga',
    numvalue: 9,
    index: 303
  },
  {
    value: 'UTC+09:00',
    name: 'Yakutsk Standard Time - Yakutsk',
    numvalue: 9,
    index: 304
  },
  {
    value: 'UTC+09:30',
    name: 'Australian Central Standard Time',
    numvalue: 9.5,
    index: 305
  },
  {
    value: 'UTC+09:30',
    name: 'Central Australia Time - Adelaide',
    numvalue: 9.5,
    index: 306
  },
  {
    value: 'UTC+09:30',
    name: 'Central Australia Time - Broken Hill',
    numvalue: 9.5,
    index: 307
  },
  {
    value: 'UTC+10:00',
    name: 'Australian Eastern Standard Time - Brisbane',
    numvalue: 10,
    index: 308
  },
  {
    value: 'UTC+10:00',
    name: 'Australian Eastern Standard Time - Lindeman',
    numvalue: 10,
    index: 309
  },
  {
    value: 'UTC+10:00',
    name: 'Chamorro Standard Time',
    numvalue: 10,
    index: 310
  },
  {
    value: 'UTC+10:00',
    name: 'Chuuk Time',
    numvalue: 10,
    index: 311
  },
  {
    value: 'UTC+10:00',
    name: 'Dumont-d’Urville Time',
    numvalue: 10,
    index: 312
  },
  {
    value: 'UTC+10:00',
    name: 'Eastern Australia Time - Hobart',
    numvalue: 10,
    index: 313
  },
  {
    value: 'UTC+10:00',
    name: 'Eastern Australia Time - Macquarie',
    numvalue: 10,
    index: 314
  },
  {
    value: 'UTC+10:00',
    name: 'Eastern Australia Time - Melbourne',
    numvalue: 10,
    index: 315
  },
  {
    value: 'UTC+10:00',
    name: 'Eastern Australia Time - Sydney',
    numvalue: 10,
    index: 316
  },
  {
    value: 'UTC+10:00',
    name: 'Papua New Guinea Time',
    numvalue: 10,
    index: 317
  },
  {
    value: 'UTC+10:00',
    name: 'Vladivostok Standard Time - Ust-Nera',
    numvalue: 10,
    index: 318
  },
  {
    value: 'UTC+10:00',
    name: 'Vladivostok Standard Time - Vladivostok',
    numvalue: 10,
    index: 319
  },
  {
    value: 'UTC+10:30',
    name: 'Lord Howe Time',
    numvalue: 10.5,
    index: 320
  },
  {
    value: 'UTC+11:00',
    name: 'Bougainville Time',
    numvalue: 11,
    index: 321
  },
  {
    value: 'UTC+11:00',
    name: 'Casey Time',
    numvalue: 11,
    index: 322
  },
  {
    value: 'UTC+11:00',
    name: 'Kosrae Time',
    numvalue: 11,
    index: 323
  },
  {
    value: 'UTC+11:00',
    name: 'Magadan Standard Time',
    numvalue: 11,
    index: 324
  },
  {
    value: 'UTC+11:00',
    name: 'New Caledonia Standard Time',
    numvalue: 11,
    index: 325
  },
  {
    value: 'UTC+11:00',
    name: 'Norfolk Island Time',
    numvalue: 11,
    index: 326
  },
  {
    value: 'UTC+11:00',
    name: 'Ponape Time',
    numvalue: 11,
    index: 327
  },
  {
    value: 'UTC+11:00',
    name: 'Sakhalin Standard Time',
    numvalue: 11,
    index: 328
  },
  {
    value: 'UTC+11:00',
    name: 'Solomon Islands Time',
    numvalue: 11,
    index: 329
  },
  {
    value: 'UTC+11:00',
    name: 'Srednekolymsk Time',
    numvalue: 11,
    index: 330
  },
  {
    value: 'UTC+11:00',
    name: 'Vanuatu Standard Time',
    numvalue: 11,
    index: 331
  },
  {
    value: 'UTC+12:00',
    name: 'Anadyr Standard Time',
    numvalue: 12,
    index: 332
  },
  {
    value: 'UTC+12:00',
    name: 'Fiji Time',
    numvalue: 12,
    index: 333
  },
  {
    value: 'UTC+12:00',
    name: 'Gilbert Islands Time',
    numvalue: 12,
    index: 334
  },
  {
    value: 'UTC+12:00',
    name: 'Marshall Islands Time - Kwajalein',
    numvalue: 12,
    index: 335
  },
  {
    value: 'UTC+12:00',
    name: 'Marshall Islands Time - Majuro',
    numvalue: 12,
    index: 336
  },
  {
    value: 'UTC+12:00',
    name: 'Nauru Time',
    numvalue: 12,
    index: 337
  },
  {
    value: 'UTC+12:00',
    name: 'New Zealand Time',
    numvalue: 12,
    index: 338
  },
  {
    value: 'UTC+12:00',
    name: 'Petropavlovsk-Kamchatski Standard Time',
    numvalue: 12,
    index: 339
  },
  {
    value: 'UTC+12:00',
    name: 'Tuvalu Time',
    numvalue: 12,
    index: 340
  },
  {
    value: 'UTC+12:00',
    name: 'Wake Island Time',
    numvalue: 12,
    index: 341
  },
  {
    value: 'UTC+12:00',
    name: 'Wallis \u0026 Futuna Time',
    numvalue: 12,
    index: 342
  },
  {
    value: 'UTC+12:45',
    name: 'Chatham Time',
    numvalue: 12.75,
    index: 343
  },
  {
    value: 'UTC+13:00',
    name: 'Apia Time',
    numvalue: 13,
    index: 344
  },
  {
    value: 'UTC+13:00',
    name: 'Phoenix Islands Time',
    numvalue: 13,
    index: 345
  },
  {
    value: 'UTC+13:00',
    name: 'Tokelau Time',
    numvalue: 13,
    index: 346
  },
  {
    value: 'UTC+13:00',
    name: 'Tonga Standard Time',
    numvalue: 13,
    index: 347
  },
  {
    value: 'UTC+14:00',
    name: 'Line Islands Time',
    numvalue: 14,
    index: 348
  }
]

@Module({dynamic: true, store, name: 'settings-module'})
export default class SettingsModule extends VuexModule implements SettingsState {
  public settings: Settings = clone(defaultSettings);

  get excludeFromTime() {
    return from12AM
  }

  get excludeToTime() {
    return to11PM
  }

  get businessSettings() {
    return this.settings;
  }

  get isAutoSharing() {
    return this.businessSettings?.contacts?.autoSharing
  }

  get timeZones() {
    return timeZones
  }

  get timeZone() {
    let index: number | undefined = this.businessSettings?.timeZoneIndex;
    if (index !== -1) {
      return index
    }
    const value = this.businessSettings?.timeZone || '';
    index = this.timeZones.find((tz) => tz.value === value)?.index;
    return index !== undefined ? index : -1
  }

  get accessProtection() {
    return this.settings.accessProtection
  }

  @Mutation
  public setTimeZone(timeZone: { value, index }) {
    if (!this.settings) {
      return
    }
    this.settings.timeZone = timeZone.value;
    this.settings.timeZoneIndex = timeZone.index;
  }

  @Mutation
  public setWorkingDays(workingDays: WorkingDay[]) {
    if (!this.settings) {
      return
    }
    this.settings.workingDays = workingDays;
  }

  @Mutation
  public setBackup(backup: BackupSetting) {
    if (!this.settings) {
      return
    }
    this.settings.backup = backup;
  }

  @Mutation
  public setAppointments(appoints: AppointsSetting) {
    if (!this.settings) {
      return
    }
    this.settings.appoints = appoints;
  }

  @Mutation
  public setSettings(settings: Settings) {
    this.settings = settings;
  }

  @Mutation
  public setAutoAlerts(alerts: AlertSetting) {
    if (!this.settings) {
      return
    }
    this.settings.autoAlert = alerts;
  }

  @Mutation
  public setAway(away: AwaySetting) {
    if (!this.settings) {
      return
    }
    this.settings.away = away;
  }

  @Mutation
  public setContactsSetting(contacts: ContactsSetting) {
    if (!this.settings) {
      return
    }
    this.settings.contacts = contacts;
  }

  @Action
  public async readSettings() {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      const businessSettingsRef = businessSettings(businessID);
      const documentSnapshot = await getDoc(businessSettingsRef);
      if (documentSnapshot.exists()) {
        const settings = documentSnapshot.data() as Settings;
        if (settings.alwaysOpen === undefined) {
          settings.alwaysOpen = defaultSettings.alwaysOpen
        }

        if (settings.timeZoneIndex === undefined) {
          const tzObj = this.timeZones.find((tz) => tz.value === settings.timeZone)
          settings.timeZoneIndex = tzObj?.index || defaultSettings.timeZoneIndex
        }

        if (settings.autoAlert.contacts === undefined) {
          settings.autoAlert.contacts = defaultSettings.autoAlert.contacts
        }
        if (settings.autoAlert.requestNew === undefined || settings.autoAlert.requestIdle === undefined) {
          settings.autoAlert.requestNew = defaultSettings.autoAlert.requestNew
          settings.autoAlert.requestIdle = defaultSettings.autoAlert.requestIdle
          settings.autoAlert.requestIdleRepeat = defaultSettings.autoAlert.requestIdleRepeat
          settings.autoAlert.requestIdleTime = defaultSettings.autoAlert.requestIdleTime
          settings.autoAlert.chatExternalIdle = defaultSettings.autoAlert.chatExternalIdle
          settings.autoAlert.chatExternalIdleRepeat = defaultSettings.autoAlert.chatExternalIdleRepeat
          settings.autoAlert.chatExternalIdleTime = defaultSettings.autoAlert.chatExternalIdleTime
          settings.autoAlert.chatExternalUnread = defaultSettings.autoAlert.chatExternalUnread
          settings.autoAlert.chatExternalUnreadRepeat = defaultSettings.autoAlert.chatExternalUnreadRepeat
          settings.autoAlert.chatExternalUnreadTime = defaultSettings.autoAlert.chatExternalUnreadTime
          settings.autoAlert.chatInternalUnread = defaultSettings.autoAlert.chatInternalUnread
          settings.autoAlert.chatInternalUnreadRepeat = defaultSettings.autoAlert.chatInternalUnreadRepeat
          settings.autoAlert.chatInternalUnreadTime = defaultSettings.autoAlert.chatInternalUnreadTime
          settings.autoAlert.appointmentNew = defaultSettings.autoAlert.appointmentNew
          settings.autoAlert.appointmentReminder = defaultSettings.autoAlert.appointmentReminder
          settings.autoAlert.appointmentReminderRepeat = defaultSettings.autoAlert.appointmentReminderRepeat
          settings.autoAlert.appointmentReminderTime = defaultSettings.autoAlert.appointmentReminderTime
          settings.autoAlert.summaryDailyActivity = defaultSettings.autoAlert.summaryDailyActivity
          settings.autoAlert.summaryDailyBusiness = defaultSettings.autoAlert.summaryDailyBusiness
        }
        if (settings.accessProtection === undefined) {
          settings.accessProtection = defaultSettings.accessProtection
        }

        this.setSettings(settings);
      } else {
        await setDoc(businessSettingsRef, defaultSettings)
        this.setSettings(defaultSettings);
      }
    } catch (e) {
      console.log(e);
    }
  }

  @Action({commit: 'setSettings'})
  public async saveSettings(settings: Settings) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await setDoc(businessSettings(businessID), settings, {merge: true});
      return settings;
    } catch (e) {
      console.log(e);
    }
    return defaultSettings;
  }

  @Action
  public async changeTimeZone(index: number) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    const timeZone: { value, name, numvalue, index } | undefined = this.timeZones.find((tz) => tz.index === index)
    if (!timeZone) {
      return
    }
    try {
      await updateDoc(businessSettings(businessID),
        'timeZone', timeZone.value,
        'timeZoneIndex', timeZone.index);
      this.setTimeZone({value: timeZone.value, index: timeZone.index});
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeWorkingDays(workingDays: WorkingDay[]) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'workingDays', workingDays);
      this.setWorkingDays(workingDays);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeBackup(backup: BackupSetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'backup', backup);
      this.setBackup(backup);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeAppointments(appoints: AppointsSetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'appoints', appoints);
      this.setAppointments(appoints);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeAutoAlerts(alerts: AlertSetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'autoAlert', alerts);
      this.setAutoAlerts(alerts);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeAway(away: AwaySetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'away', away);
      this.setAway(away);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeContactsSetting(contacts: ContactsSetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'contacts', contacts);
      this.setContactsSetting(contacts);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async changeAutoSharing(value: boolean) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    const contacts = {...this.settings?.contacts, autoSharing: value};
    try {
      await updateDoc(businessSettings(businessID), 'contacts', contacts);
      this.setContactsSetting(contacts);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async setDefaultAppointmentsContact(contact: any) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      this.businessSettings!.appoints.contact = {
        id: contact.id,
        uid: contact.associate.id,
        business: contact.business,
        name: contact.name,
        email: contact.email,
        photoUrl: contact.photoUrl || null,
        position: contact.position,
        associate: contact.associate,
        type: contact.type,
        added: Timestamp.now()
      };
      const contactId = this.businessSettings!.appoints.contact!.id!;
      await updateDoc(businessDirectoryContact(businessID, contactId),
        'defaultAppointContact', true)
    } catch (e) {
      console.error(e)
    }
  }

  @Mutation
  public setBusinessAlwaysOpen(value: boolean) {
    this.settings.alwaysOpen = value
  }

  @Action
  public async alwaysOpenChanged(value: boolean) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    try {
      await updateDoc(businessSettings(businessID), 'alwaysOpen', value);
      this.setBusinessAlwaysOpen(value);
    } catch (e) {
      console.log(e);
    }
    if (value) {
      this.settings.workingDays.forEach((day) => {
        day.active = true
        day.from = from12AM
        day.to = to11PM
        day.start = from12AM
        day.end = to11PM
      })
      try {
        await updateDoc(businessSettings(businessID), 'workingDays', this.settings.workingDays);
        this.setWorkingDays(this.settings.workingDays);
      } catch (e) {
        console.log(e);
      }
    }
  }

  @Mutation
  public setAccessProtection(value: BusinessPageAccessSetting) {
    this.settings.accessProtection = value
  }

  @Action
  public async accessProtectionCodeChanged(code: string) {
    const accessProtection = this.settings.accessProtection
    accessProtection.code = code
    await this.updateAccessProtection(accessProtection)
  }

  @Action
  public async accessProtectionActiveChanged(active: boolean) {
    const accessProtection = this.settings.accessProtection
    accessProtection.active = active
    await this.updateAccessProtection(accessProtection)
  }

  @Action
  public async updateAccessProtection(accessProtection: BusinessPageAccessSetting) {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return;
    }
    await updateDoc(businessSettings(businessID), 'accessProtection', accessProtection);
    this.setAccessProtection(accessProtection);
  }

  private get businessSettingsRef() {
    const businessID = this.context.rootGetters.business.id;
    if (!businessID) {
      return null;
    }
    return businessSettings(businessID);
  }

  @Mutation
  public setBackupContact(contact: any) {
    if (!!this.settings.backup) {
      this.settings.backup.contact = {
        id: contact.id,
        uid: contact.associate.id,
        business: contact.business,
        name: contact.name,
        email: contact.email,
        photoUrl: contact.photoUrl || null,
        position: contact.position,
        workingStatus: contact.associate.workingStatus || null,
        status: contact.associate.status || null,
        type: contact.type,
        added: serverTimestamp()
      };
    }
  }
}

export const settingsStore = getModule(SettingsModule);
